<template>
  <div class="img-preview-box" style="color: #fff">
    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="0"
      controls
      indicators
    >
      <b-carousel-slide v-for="(item, index) in mediaListProp" :key="index">
        <template #img>
          <div style="width: 100%">
            <img
              class="img-auto"
              @click="$emit('closeViewPhoto', false)"
              :src="item.url"
            />
          </div>
        </template>
      </b-carousel-slide>
    </b-carousel>
    <i class="ri-close-circle-line" @click="$emit('closeViewPhoto', false)"></i>
  </div>
  <!--  -->
</template>

<script>
export default {
  data () {
    return {
      slide: 0
    };
  },

  props: {
    mediaListProp: {
      type: Array,
      default: []
    }
  },

  components: {},

  created () { },

  methods: {}
}

</script>
<style lang="scss" scoped>
.img-preview-box {
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: relative;
  #carousel-1 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  > i {
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 30px;
    color: #fff;
  }
}
</style>