/**
 *  功能: 生成随机颜色
 */
const randomRgb = function () {
  let R = Math.floor(Math.random() * 255);
  let G = Math.floor(Math.random() * 255);
  let B = Math.floor(Math.random() * 255);
  return {
    background: "rgb(" + R + "," + G + "," + B + ")"
  };
}
// 节流
const throttle = function (fn, interval) {
  var last;
  var timer;
  var interval = interval || 1000;
  return function () {
    var th = this;
    var args = arguments;
    var now = +new Date();
    if (last && now - last < interval) {
      clearTimeout(timer);
      timer = setTimeout(function () {
        last = now;
        fn.apply(th, args);
      }, interval);
    } else {
      last = now;
      fn.apply(th, args);
    }
  }
}
export {
  randomRgb,
  throttle
}
